import http, { AUTH_ROUTES } from "../../../../services/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../../components/NotificationHandler";
import { DATA_ROWS } from "../../../../app/constants";
export const getResidents = async (
  userId,
  setLoading,
  setResidentRows,
  skip = 0,
  limit = DATA_ROWS.LIMIT
) => {
  const page = skip * limit;
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_RESIDENT({ userId, page, limit }));
    setLoading(false);
    setResidentRows(data.tenants);
  } catch (e) {
    setLoading(false);
    setResidentRows([]);
    // errorNotifier(e.response?.data?.result?.message);
  } finally {
    setLoading(false);
  }
};

export const getAllResidents = async (
  userId,
  setLoading,
  setAllResidentRows
) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ALL_RESIDENT({ userId }));
    setLoading(false);
    setAllResidentRows(data?.tenants);
  } catch (e) {
    setLoading(false);
    setAllResidentRows([]);
    // errorNotifier(e.response?.data?.result?.message);
  } finally {
    setLoading(false);
  }
};

export const updateResident = async (payload, setLoading, onClose) => {
  try {
    await http.put(
      AUTH_ROUTES.UPDATE_RESIDENT(payload.userId, payload._id),
      payload
    );
    setLoading(false);
    successNotifier("Successfully updated a resident");
    onClose();
  } catch (e) {
    errorNotifier(e.response?.data?.result?.message);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const createResident = async (payload, setLoading, onClose) => {
  try {
    await http.post(AUTH_ROUTES.CREATE_RESIDENT, payload);
    setLoading(false);
    successNotifier("Successfully created a resident");
    onClose();
  } catch (e) {
    setLoading(false);
    onClose();
    if (e?.response) {
      errorNotifier(
        e?.response?.data?.errors?.[0]?.msg ||
          e?.response?.data?.result?.message
      );
      console.log("error", e.response)
    } else {
      errorNotifier("Please check your network connection");
    }

    throw new Error();
  } finally {
    setLoading(false);
  }
};

export const banResident = async (payload) => {
  try {
    await http.put(
      AUTH_ROUTES.UPDATE_RESIDENT(payload.userId, payload._id),
      payload
    );
    successNotifier("Successfully banned resident");
  } catch (e) {
    errorNotifier(e?.response?.data?.errors[0]?.msg);
  }
};

export const deleteResident = async (payload) => {
  try {
    await http.delete(AUTH_ROUTES.UPDATE_RESIDENT(payload.userId, payload._id));
    successNotifier("Successfully deleted resident");
  } catch (e) {
    errorNotifier(e?.response?.data?.errors[0]?.msg);
  }
};
